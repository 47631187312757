import { Nation } from './constants';
import GameBoard from './gameBoard';

const nodes = [
  { isOcean: true, name: 'bayofbiscay' },
  { isOcean: true, name: 'blacksea' },
  { isOcean: true, name: 'westernmediterraneansea' },
  { isOcean: true, name: 'ioniansea' },
  { isOcean: true, name: 'easternmediterraneansea' },
  { isOcean: true, name: 'northsea' },
  { isOcean: true, name: 'northatlantic' },
  { isOcean: true, name: 'balticsea' },
  { isOcean: true, name: 'englishchannel' },
  { isOcean: false, name: 'turkey' },
  { isOcean: false, name: 'bulgaria' },
  { isOcean: false, name: 'romania' },
  { isOcean: false, name: 'westbalkan' },
  { isOcean: false, name: 'greece' },
  { isOcean: false, name: 'tunis' },
  { isOcean: false, name: 'algeria' },
  { isOcean: false, name: 'morocco' },
  { isOcean: false, name: 'spain' },
  { isOcean: false, name: 'portugal' },
  { isOcean: false, name: 'belgium' },
  { isOcean: false, name: 'holland' },
  { isOcean: false, name: 'denmark' },
  { isOcean: false, name: 'norway' },
  { isOcean: false, name: 'sweden' },
  {
    nation: Nation.RU, factoryType: 'armaments', name: 'moscow', isHome: true,
  },
  { nation: Nation.RU, factoryType: 'armaments', name: 'warsaw' },
  {
    nation: Nation.RU,
    factoryType: 'shipyard',
    name: 'stpetersburg',
    egress: 'balticsea',
  },
  { nation: Nation.RU, factoryType: 'armaments', name: 'kiev' },
  {
    nation: Nation.RU,
    factoryType: 'shipyard',
    name: 'odessa',
    isHome: true,
    egress: 'blacksea',
  },
  {
    nation: Nation.GE,
    factoryType: 'shipyard',
    name: 'danzig',
    egress: 'balticsea',
  },
  {
    nation: Nation.GE, factoryType: 'armaments', name: 'berlin', isHome: true,
  },
  { nation: Nation.GE, factoryType: 'armaments', name: 'munich' },
  { nation: Nation.GE, factoryType: 'armaments', name: 'cologne' },
  {
    nation: Nation.GE,
    factoryType: 'shipyard',
    name: 'hamburg',
    isHome: true,
    egress: 'northsea',
  },
  {
    nation: Nation.GB,
    factoryType: 'shipyard',
    name: 'dublin',
    egress: 'northatlantic',
  },
  {
    nation: Nation.GB,
    factoryType: 'shipyard',
    name: 'edinburgh',
    egress: 'northsea',
  },
  {
    nation: Nation.GB,
    factoryType: 'shipyard',
    name: 'liverpool',
    isHome: true,
    egress: 'northatlantic',
  },
  { nation: Nation.GB, factoryType: 'armaments', name: 'sheffield' },
  {
    nation: Nation.GB,
    factoryType: 'shipyard',
    name: 'london',
    isHome: true,
    egress: 'englishchannel',
  },
  {
    nation: Nation.FR, factoryType: 'armaments', name: 'paris', isHome: true,
  },
  { nation: Nation.FR, factoryType: 'armaments', name: 'dijon' },
  {
    nation: Nation.FR,
    factoryType: 'shipyard',
    name: 'marseille',
    egress: 'westernmediterraneansea',
  },
  {
    nation: Nation.FR,
    factoryType: 'shipyard',
    name: 'bordeaux',
    isHome: true,
    egress: 'bayofbiscay',
  },
  {
    nation: Nation.FR,
    factoryType: 'shipyard',
    name: 'brest',
    egress: 'englishchannel',
  },
  {
    nation: Nation.IT,
    factoryType: 'shipyard',
    name: 'genoa',
    egress: 'westernmediterraneansea',
  },
  {
    nation: Nation.IT,
    factoryType: 'shipyard',
    name: 'venice',
    egress: 'ioniansea',
  },
  { nation: Nation.IT, factoryType: 'armaments', name: 'florence' },
  {
    nation: Nation.IT, factoryType: 'armaments', name: 'rome', isHome: true,
  },
  {
    nation: Nation.IT,
    factoryType: 'shipyard',
    name: 'naples',
    isHome: true,
    egress: 'westernmediterraneansea',
  },
  {
    nation: Nation.AH,
    factoryType: 'shipyard',
    name: 'trieste',
    egress: 'ioniansea',
  },
  {
    nation: Nation.AH, factoryType: 'armaments', name: 'vienna', isHome: true,
  },
  {
    nation: Nation.AH,
    factoryType: 'armaments',
    name: 'budapest',
    isHome: true,
  },
  { nation: Nation.AH, factoryType: 'armaments', name: 'prague' },
  { nation: Nation.AH, factoryType: 'armaments', name: 'lemberg' },
];

const edges = [
  ['moscow', 'kiev'],
  ['moscow', 'warsaw'],
  ['moscow', 'stpetersburg'],
  ['warsaw', 'stpetersburg'],
  ['warsaw', 'moscow'],
  ['warsaw', 'kiev'],
  ['warsaw', 'lemberg'],
  ['warsaw', 'prague'],
  ['warsaw', 'danzig'],
  ['stpetersburg', 'moscow'],
  ['stpetersburg', 'kiev'],
  ['stpetersburg', 'warsaw'],
  ['stpetersburg', 'danzig'],
  ['stpetersburg', 'balticsea'],
  ['kiev', 'moscow'],
  ['kiev', 'odessa'],
  ['kiev', 'romania'],
  ['kiev', 'lemberg'],
  ['kiev', 'warsaw'],
  ['odessa', 'kiev'],
  ['odessa', 'blacksea'],
  ['odessa', 'romania'],
  ['blacksea', 'odessa'],
  ['blacksea', 'turkey'],
  ['blacksea', 'easternmediterraneansea'],
  ['blacksea', 'bulgaria'],
  ['blacksea', 'romania'],
  ['turkey', 'blacksea'],
  ['turkey', 'easternmediterraneansea'],
  ['turkey', 'bulgaria'],
  ['easternmediterraneansea', 'turkey'],
  ['easternmediterraneansea', 'ioniansea'],
  ['easternmediterraneansea', 'greece'],
  ['easternmediterraneansea', 'bulgaria'],
  ['easternmediterraneansea', 'blacksea'],
  ['bulgaria', 'romania'],
  ['bulgaria', 'blacksea'],
  ['bulgaria', 'turkey'],
  ['bulgaria', 'easternmediterraneansea'],
  ['bulgaria', 'greece'],
  ['bulgaria', 'westbalkan'],
  ['romania', 'lemberg'],
  ['romania', 'kiev'],
  ['romania', 'odessa'],
  ['romania', 'blacksea'],
  ['romania', 'bulgaria'],
  ['romania', 'westbalkan'],
  ['romania', 'budapest'],
  ['westbalkan', 'budapest'],
  ['westbalkan', 'romania'],
  ['westbalkan', 'bulgaria'],
  ['westbalkan', 'greece'],
  ['westbalkan', 'ioniansea'],
  ['westbalkan', 'trieste'],
  ['greece', 'westbalkan'],
  ['greece', 'bulgaria'],
  ['greece', 'easternmediterraneansea'],
  ['greece', 'ioniansea'],
  ['ioniansea', 'trieste'],
  ['ioniansea', 'westbalkan'],
  ['ioniansea', 'greece'],
  ['ioniansea', 'easternmediterraneansea'],
  ['ioniansea', 'tunis'],
  ['ioniansea', 'westernmediterraneansea'],
  ['ioniansea', 'naples'],
  ['lemberg', 'kiev'],
  ['lemberg', 'romania'],
  ['lemberg', 'budapest'],
  ['lemberg', 'prague'],
  ['lemberg', 'warsaw'],
  ['budapest', 'lemberg'],
  ['budapest', 'romania'],
  ['budapest', 'westbalkan'],
  ['budapest', 'trieste'],
  ['budapest', 'vienna'],
  ['budapest', 'prague'],
  ['prague', 'danzig'],
  ['prague', 'warsaw'],
  ['prague', 'lemberg'],
  ['prague', 'budapest'],
  ['prague', 'vienna'],
  ['prague', 'munich'],
  ['prague', 'berlin'],
  ['vienna', 'prague'],
  ['vienna', 'budapest'],
  ['vienna', 'trieste'],
  ['vienna', 'venice'],
  // ["vienna", "switzerland"],
  ['vienna', 'munich'],
  ['trieste', 'vienna'],
  ['trieste', 'budapest'],
  ['trieste', 'westbalkan'],
  ['trieste', 'ioniansea'],
  ['trieste', 'venice'],
  ['venice', 'vienna'],
  ['venice', 'trieste'],
  ['venice', 'ioniansea'],
  ['venice', 'rome'],
  ['venice', 'florence'],
  ['venice', 'genoa'],
  ['rome', 'venice'],
  ['rome', 'ioniansea'],
  ['rome', 'naples'],
  ['rome', 'westernmediterraneansea'],
  ['rome', 'florence'],
  ['naples', 'rome'],
  ['naples', 'ioniansea'],
  ['naples', 'westernmediterraneansea'],
  ['florence', 'genoa'],
  ['florence', 'venice'],
  ['florence', 'rome'],
  ['florence', 'westernmediterraneansea'],
  // ["genoa", "switzerland"],
  ['genoa', 'vienna'],
  ['genoa', 'venice'],
  ['genoa', 'florence'],
  ['genoa', 'westernmediterraneansea'],
  ['genoa', 'marseille'],
  ['marseille', 'dijon'],
  // ["marseille", "switzerland"],
  ['marseille', 'genoa'],
  ['marseille', 'westernmediterraneansea'],
  ['marseille', 'spain'],
  ['marseille', 'bordeaux'],
  ['bordeaux', 'brest'],
  ['bordeaux', 'dijon'],
  ['bordeaux', 'marseille'],
  ['bordeaux', 'spain'],
  ['bordeaux', 'bayofbiscay'],
  ['brest', 'englishchannel'],
  ['brest', 'paris'],
  ['brest', 'dijon'],
  ['brest', 'bordeaux'],
  ['brest', 'bayofbiscay'],
  ['paris', 'englishchannel'],
  ['paris', 'belgium'],
  ['paris', 'dijon'],
  ['paris', 'brest'],
  ['dijon', 'paris'],
  ['dijon', 'belgium'],
  ['dijon', 'munich'],
  // ["dijon", "switzerland"],
  ['dijon', 'marseille'],
  ['dijon', 'bordeaux'],
  ['dijon', 'brest'],
  ['tunis', 'westernmediterraneansea'],
  ['tunis', 'algeria'],
  ['algeria', 'westernmediterraneansea'],
  ['algeria', 'tunis'],
  ['algeria', 'morocco'],
  ['morocco', 'bayofbiscay'],
  ['morocco', 'algeria'],
  ['westernmediterraneansea', 'marseille'],
  ['westernmediterraneansea', 'genoa'],
  ['westernmediterraneansea', 'florence'],
  ['westernmediterraneansea', 'rome'],
  ['westernmediterraneansea', 'naples'],
  ['westernmediterraneansea', 'ioniansea'],
  ['westernmediterraneansea', 'tunis'],
  ['westernmediterraneansea', 'algeria'],
  ['westernmediterraneansea', 'bayofbiscay'],
  ['westernmediterraneansea', 'spain'],
  ['spain', 'bayofbiscay'],
  ['spain', 'bordeaux'],
  ['spain', 'marseille'],
  ['spain', 'westernmediterraneansea'],
  ['spain', 'portugal'],
  ['portugal', 'bayofbiscay'],
  ['portugal', 'spain'],
  ['bayofbiscay', 'northatlantic'],
  ['bayofbiscay', 'englishchannel'],
  ['bayofbiscay', 'brest'],
  ['bayofbiscay', 'bordeaux'],
  ['bayofbiscay', 'spain'],
  ['bayofbiscay', 'portugal'],
  ['bayofbiscay', 'morocco'],
  ['northatlantic', 'dublin'],
  ['northatlantic', 'edinburgh'],
  ['northatlantic', 'northsea'],
  ['northatlantic', 'liverpool'],
  ['northatlantic', 'london'],
  ['northatlantic', 'englishchannel'],
  ['northatlantic', 'bayofbiscay'],
  ['dublin', 'northatlantic'],
  ['edinburgh', 'northatlantic'],
  ['edinburgh', 'northsea'],
  ['edinburgh', 'sheffield'],
  ['edinburgh', 'liverpool'],
  ['liverpool', 'edinburgh'],
  ['liverpool', 'sheffield'],
  ['liverpool', 'london'],
  ['liverpool', 'northatlantic'],
  ['sheffield', 'edinburgh'],
  ['sheffield', 'northsea'],
  ['sheffield', 'london'],
  ['sheffield', 'liverpool'],
  ['london', 'sheffield'],
  ['london', 'northsea'],
  ['london', 'englishchannel'],
  ['london', 'northatlantic'],
  ['london', 'liverpool'],
  ['northsea', 'norway'],
  ['northsea', 'balticsea'],
  ['northsea', 'denmark'],
  ['northsea', 'hamburg'],
  ['northsea', 'holland'],
  ['northsea', 'englishchannel'],
  ['northsea', 'london'],
  ['northsea', 'sheffield'],
  ['northsea', 'edinburgh'],
  ['northsea', 'northatlantic'],
  ['englishchannel', 'northatlantic'],
  ['englishchannel', 'london'],
  ['englishchannel', 'holland'],
  ['englishchannel', 'belgium'],
  ['englishchannel', 'paris'],
  ['englishchannel', 'brest'],
  ['englishchannel', 'bayofbiscay'],
  ['belgium', 'englishchannel'],
  ['belgium', 'holland'],
  ['belgium', 'cologne'],
  ['belgium', 'munich'],
  ['belgium', 'dijon'],
  ['belgium', 'paris'],
  ['holland', 'northsea'],
  ['holland', 'hamburg'],
  ['holland', 'cologne'],
  ['holland', 'belgium'],
  ['holland', 'englishchannel'],
  ['cologne', 'belgium'],
  ['cologne', 'holland'],
  ['cologne', 'hamburg'],
  ['cologne', 'berlin'],
  ['cologne', 'munich'],
  ['munich', 'cologne'],
  ['munich', 'berlin'],
  ['munich', 'prague'],
  ['munich', 'vienna'],
  // ["munich", "switzerland"],
  ['munich', 'dijon'],
  ['munich', 'belgium'],
  ['berlin', 'balticsea'],
  ['berlin', 'danzig'],
  ['berlin', 'prague'],
  ['berlin', 'munich'],
  ['berlin', 'cologne'],
  ['berlin', 'hamburg'],
  ['hamburg', 'denmark'],
  ['hamburg', 'balticsea'],
  ['hamburg', 'berlin'],
  ['hamburg', 'cologne'],
  ['hamburg', 'holland'],
  ['hamburg', 'northsea'],
  ['danzig', 'balticsea'],
  ['danzig', 'stpetersburg'],
  ['danzig', 'warsaw'],
  ['danzig', 'prague'],
  ['danzig', 'berlin'],
  ['norway', 'northsea'],
  ['norway', 'balticsea'],
  ['norway', 'sweden'],
  ['sweden', 'norway'],
  ['sweden', 'balticsea'],
  ['balticsea', 'stpetersburg'],
  ['balticsea', 'danzig'],
  ['balticsea', 'berlin'],
  ['balticsea', 'hamburg'],
  ['balticsea', 'denmark'],
  ['balticsea', 'northsea'],
  ['balticsea', 'sweden'],
];

export default new GameBoard({ nodes, edges });
